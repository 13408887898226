import { Button, CssBaseline, Stack, TextField } from "@mui/material"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ApiConnector } from "../Api"
import { RoleDTO } from "../Api/ApiConnector"
import { BackgroundImage } from "../BackgroundImage"
import { AuthContext, AuthContextValue } from "../utilities"
import { CafeTypography } from "./CafeTypography"
import { Header } from "./Header"

export const SignIn = () => {
    const { t } = useTranslation();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const auth = useContext<AuthContextValue>(AuthContext);
    const nav = useNavigate();

    return (
        <>
            <CssBaseline />
            <Header createTabs={false} />
            <BackgroundImage source="cafe"/>
            <div className="container-box" style={{
                width: "100%",
                height: "100%",
                padding: 32
            }}>

                <Stack direction="column">
                    <TextField label={t("system.username")} onChange={(e) => {
                        setUsername(e.currentTarget.value)
                    }} />
                    <TextField label={t("system.password")} type="password" onChange={(e) => {
                        setPassword(e.currentTarget.value)
                    }} />
                    <Button onClick={async () => {
                        const result = await ApiConnector.login(username, password)
                        alert(result ? t("system.successfulLogin") : t("system.error"));
                        if (result) {
                            if (auth.setLogin) {
                                auth.setLogin(true);
                            }
                            
                            if (auth.setJwtToken) {
                                auth.setJwtToken(result);
                            }

                            if (auth.setRoles) {
                                auth.setRoles(await ApiConnector.getTs<RoleDTO>(result, "User", "Role"));
                            }

                            if (auth.setIsRoot) {
                                auth.setIsRoot(await ApiConnector.isRoot(result))
                            }

                            nav("/");
                        }
                    }}><CafeTypography>{t("system.signin")}</CafeTypography></Button>
                </Stack>
            </div>
        </>
    );
}
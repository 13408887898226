import cafe_background_video from "./Videos/cafe_background_video.mp4";
import bg from "./bg.jpg";
import cafe from "./cafe.jpg";
import photo_9 from "./photo_9.jpg";

export type importedImage = keyof typeof Images;

export const Images = {
    photo_9, cafe, 
    cafe_background_video,
    bg
}

import Image from "mui-image";
import { useTranslation } from "react-i18next";
import { CafeTypography } from "../../Components";
import { ImageOrEmptyProps } from "./AdminRoom";

export const ImageOrEmpty = (props: ImageOrEmptyProps) => {
    const { t } = useTranslation();
    const { url } = props;
    return !url ? <CafeTypography>
        {t("data.empty")}
    </CafeTypography> : <Image height="128px" width="128px" src={url} />;
};

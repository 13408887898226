import { Delete, Edit } from "@material-ui/icons";
import { Button, ButtonBase, Dialog, DialogContent, DialogTitle, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Dispatch, RefObject, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CafeTypography } from "../CafeTypography";

type Keyable<T> = {
    [key in keyof T]: T[key];
};

type SpecialDataField<T> = {
    [key in keyof T]?: (x: T) => React.ReactNode;
}

type TransformField<T> = {
    [key in keyof T]?: (x: T[key]) => string;
}

type SpecialRefDataField<T> = {
    [key in keyof T]?: (x: RefObject<T>) => React.ReactNode;
}

type FilterCouple<T> = {
    component: (v: T | null, update: Dispatch<SetStateAction<T | null>>) => React.ReactNode
    predicate: (value: T, filter: T) => boolean;
}

type FilterDataField<T> = {
    [key in keyof T]?: FilterCouple<T>;
}

export interface DataTableProps<T extends { id: string }> {
    data: Keyable<T>[];
    label: string;
    schema: (keyof T & string)[]
    condition: boolean
    editable: boolean;
    deletable: boolean;
    filterable?: boolean;
    initFilter?: T
    filters?: FilterDataField<T>;
    specialFields?: SpecialDataField<T>;
    editFields?: SpecialRefDataField<T>;
    transformFields?: TransformField<T>;
    onDelete?: (data: T) => Promise<void>;
    onUpdate?: (data: T) => Promise<void>;

}

export const DataTable = <T extends { id: string }>(props: DataTableProps<T>) => {
    const { label, schema,
        specialFields, editable, deletable,
        onDelete, editFields,
        transformFields, onUpdate, condition, filterable, filters, initFilter } = props;
    const [currentId, setCurrentId] = useState<string | null>(null);
    const updatableData = useRef<T | null>(null);

    const [filterableData, setFilter] = useState<T | null>(initFilter ? initFilter : null);

    let { data } = props;

    const { t } = useTranslation();
    
    if (filters && filterable) {
        for (const k of Object.keys(filters)) {
            const key = k as keyof T

            if (!filters[key]) continue;

            if (!filterableData) continue;

            data = data.filter(v => {
                return filters[key]!.predicate(v, filterableData)
            })
        }
    }
    
    if (condition === false) return <></>

    return (
        <div style={{ overflowX: "auto" }}>
            <CafeTypography fontSize={24}>{label}</CafeTypography>
            <Table>
                <TableHead sx={{
                    minWidth: "auto"
                }}>
                    { filterable ? 
                    <TableRow>
                        {schema.map(k => {
                            const key = k as keyof T

                            return <TableCell>
                                {filters && key in filters && filters[key] ? filters[key]?.component(filterableData, setFilter) : null}
                            </TableCell>
                        })}
                        {editable || deletable ? <TableCell>
                            <CafeTypography>{t("data.filter")}</CafeTypography>
                        </TableCell> : null}
                    </TableRow> 
                    : null}
                    <TableRow>
                        {schema.map(k => {
                            return <TableCell>
                                <CafeTypography >{t(`data.fields.${k}`)}</CafeTypography>
                            </TableCell>
                        })} {editable || deletable ? <TableCell>
                            <CafeTypography>{t("data.manipulate")}</CafeTypography>
                        </TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((v) => {
                        return <TableRow key={v.id}>
                            {schema.map((k) => {
                                const key = k as keyof T;
                                if (key === "id") {
                                    return;
                                }

                                if (specialFields && key in specialFields && specialFields[key]) {
                                    return <TableCell>
                                        {specialFields[key]!(v)}
                                    </TableCell>
                                }

                                return (
                                    <TableCell>
                                        <CafeTypography sx={{
                                            wordBreak: "break-all"
                                        }}>
                                            {transformFields && key in transformFields && transformFields[key] ? transformFields[key]!(v[key]) : v[key] as string}
                                        </CafeTypography>
                                    </TableCell>)
                            })} {editable || deletable ? <TableCell>
                                <Stack direction="row"> {currentId !== v.id ?
                                    <>
                                        {deletable ? <ButtonBase onClick={() => {
                                            if (onDelete) {
                                                onDelete(v);
                                            } else {
                                                console.warn("onDelete isn't specified.");
                                            }
                                        }}>
                                            <Delete />
                                        </ButtonBase> : null}
                                        {editable ? <ButtonBase onClick={() => {
                                            updatableData.current = v;
                                            setCurrentId(v.id);
                                        }}>
                                            <Edit />
                                        </ButtonBase> : null}
                                    </> : <Dialog open={currentId === v.id} onClose={() => {
                                        setCurrentId(null);
                                    }}>
                                        <DialogTitle>
                                            <CafeTypography>{label}</CafeTypography>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Stack>
                                                {schema.map(key => {
                                                    if (editable &&
                                                        currentId === v.id &&
                                                        editFields &&
                                                        editFields[key]) {
                                                        return <TableCell>
                                                            {editFields[key]!(updatableData)}
                                                        </TableCell>
                                                    }
                                                })}
                                                <Button onClick={() => {
                                                    if (onUpdate && updatableData.current) {
                                                        onUpdate(updatableData.current)
                                                        updatableData.current = null;
                                                        setCurrentId("");
                                                    } else {
                                                        console.warn("onUpdate isn't specified.");
                                                    }
                                                }}>
                                                    {t("data.submit")}
                                                </Button>
                                            </Stack>
                                        </DialogContent>
                                    </Dialog>
                                }
                                </Stack>
                            </TableCell> : null}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </div>
    );
}
import { Box, CircularProgress, MenuItem, Select, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateWidgetDTO, WidgetApi, WidgetDTO } from "../../Api/Widgets";
import { CafeTypography, DataTable } from "../../Components";
import { InsertForm } from "../../Components/DataTable";
import { LocalizableInput, ParentWidgetSelect } from "../../Components/LocalizableInput";
import { AuthContext, LanguageContext, hasPermission, translateByLocales, typographyStyle } from "../../utilities";

interface Tag {
    humanName: string
    tag: string
}


interface TagSelectProps {
    onChange: (tag: string | string[]) => void;
    value?: string | string[]
    multiple?: boolean
}

export const TagSelect = (props: TagSelectProps) => {
    const tags: Tag[] = [
        {humanName: "Заголовок/Телефон", tag: "header_phone"},
        {humanName: "Заголовок/Время открытия", tag: "header_time"},
        {humanName: "О нас", tag: "about"},
        {humanName: "Контакты", tag: "contact"},
        {humanName: "Дом", tag: "home"},
        {humanName: "Пустое Меню", tag: "empty_menu"}
    ]
    const { value, onChange, multiple } = props;

    const def = multiple ? [] as string[] : ""
    
    const [currentValue, setCurrentValue] = useState<string | string[]>(value ? value : def)

    useEffect(()=> {
        onChange(currentValue);
    }, [currentValue])

    return <Select
    defaultValue={def}

    multiple={multiple}

    sx={{
        height: '2.5rem',
        '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white'
            },
            '& .MuiSvgIcon-root': {
                color: 'white'
            }
        }}

        onChange={event => {
            setCurrentValue(event.target.value);
            if (onChange) {
                onChange(event.target.value)
            }
        }}
    >
        {tags.map((v, index) => {
            return <MenuItem value={v.tag} key={`${v.tag}+index${index}`}>
                {v.humanName}
        </MenuItem>
    })}
  </Select>
}

export const WidgetEditor = () => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { jwtToken, roles, isRoot } = useContext(AuthContext);
    const [widgets, setWidgets] = useState<WidgetDTO[] | null>();
    const [forceUpdate, setForceUpdate] = useState(false);
    useEffect(() => {
        const load = async () => {
            setWidgets(await WidgetApi.getAllWidgets());
            setForceUpdate(!forceUpdate)
        }

        load();
    }, [language])

    return (
        <>
            <Box>
                <CafeTypography sx={{ ...typographyStyle }} variant="h3">{t("system.adminRoom")}</CafeTypography>
            </Box>

            <Stack direction="column">
                {widgets && widgets.length > 0 ?
                    <DataTable<WidgetDTO>

                    initFilter={{
                        id: "",
                        parentId: [],
                        tag: "",
                        children: null,
                        title: [],
                        content: []
                    }}

                    // filterable

                    filters={{
                        tag: {
                            component: (v, setV) => {
                                return <TagSelect multiple onChange={(tag) => {
                                    if (!v) return;

                                    setV({...v, tag: tag})
                                }} />
                            },
                            predicate: (v, f) => {
                                if (!Array.isArray(f.tag)) return false;
                                if (Array.isArray(v.tag)) return false;

                                if (f.tag.length === 0) return true;

                                return f.tag.includes(v.tag);
                            }
                        },
                        parentId: {
                            component: (v, setV) => {
                                return <ParentWidgetSelect update={forceUpdate} multiple onChange={(id) => {
                                    if (!v) return;

                                    setV({...v, parentId: id})
                                }} />
                            },
                            predicate: (v, f) => {
                                if (!Array.isArray(f.parentId)) return false;
                                if (Array.isArray(v.parentId)) return false;
                                if (!v.parentId && f.parentId.length === 0 || !f.parentId) return true;

                                if (v.parentId) {
                                    return f.parentId.includes(v.parentId);
                                } else {
                                    return false;
                                }
                            }
                        }
                    }}

                    condition={hasPermission("SELECT", "widgets", roles, isRoot)}
                        transformFields={{
                            title: v => {
                                return translateByLocales(v, language);
                            },
                            content: v => translateByLocales(v, language),
                            parentId: v => {
                                if (!v) {
                                    return t("data.empty");
                                }
                                const w = widgets.find(x => x.id === v)

                                if (!w) {
                                    return t("data.empty");
                                }

                                return translateByLocales(w.title, language);

                            }
                        }}
                        schema={['tag', "title", "content", "parentId"]}
                        editFields={{
                            title: (data) => {
                                return <LocalizableInput
                                    value={data.current?.title}
                                    onChange={(translation) => {
                                        if (!data.current) return;
                                        data.current.title = translation;
                                    }}
                                    enableCopy 
                                    />
                            },
                            content: (data) => {
                                return <LocalizableInput
                                    enableCopy
                                    multiline
                                    value={data.current?.content}
                                    onChange={(translation) => {
                                        if (!data.current) return;
                                        data.current.content = translation;
                                    }} />
                            },
                            tag: (data) => {
                                return <TagSelect
                                    value={data.current?.tag}
                                    onChange={(tag) => {
                                        if (!data.current) return;
                                        data.current.tag = tag;
                                    }}
                                />
                            },
                            parentId: (data) => {
                                return <ParentWidgetSelect update={forceUpdate}
                                onChange={(id) => {
                                    if (!data.current) return;
                                    data.current.parentId = id;
                                }} value={data.current?.parentId} />
                            }
                        }}

                        editable={hasPermission("UPDATE", "widgets", roles, isRoot)}
                        deletable={hasPermission("DELETE", "widgets", roles, isRoot)}

                        onUpdate={async data => {
                            setWidgets(null);
                            await WidgetApi.updateWidget(data.id, data, jwtToken, err => alert(err));
                            setWidgets(await WidgetApi.getAllWidgets());
                            setForceUpdate(!forceUpdate)
                        }}

                        onDelete={async data => {
                            const ok = await WidgetApi.deleteWidget(data.id, jwtToken, err => {
                                alert(err);
                            })
                            if (ok) {
                                setWidgets(await WidgetApi.getAllWidgets());
                                setForceUpdate(!forceUpdate)
                            }
                        }}


                        label="Widgets" data={widgets} /> : (widgets && widgets.length === 0 ?
                            <CafeTypography variant="h6">{t("data.empty")}</CafeTypography> : <CircularProgress />)}
                <InsertForm<WidgetDTO>
                    condition={hasPermission("INSERT", "widgets", roles, isRoot)}

                    label={t("data.insert")}
                    schema={['tag', "title", "content", "parentId"]}
                    specialFields={
                        {
                            title: {
                                specialComponent: props => {
                                    return <LocalizableInput
                                        enableCopy
                                        onChange={(translation) => {
                                            props.handleChange(translation);
                                        }}
                                    />
                                }
                            },
                            content: {
                                specialComponent: props => {
                                    return <LocalizableInput
                                        enableCopy
                                        multiline
                                        onChange={(translation) => {
                                            props.handleChange(translation);
                                        }}
                                    />
                                }
                            },
                            tag: {
                                specialComponent: props => {
                                    return <TagSelect
                                    onChange={(tag) => {
                                        props.handleChange(tag);
                                    }}
                                />
                                }
                            },
                            parentId: {
                                specialComponent: props => {
                                    return <ParentWidgetSelect update={forceUpdate}
                                        onChange={(id)=> {
                                            props.handleChange(id);
                                        }}
                                    />
                                }
                            }
                        }
                    }

                    onSubmit={async (data) => {
                        setWidgets(null);
                        const postedData = await WidgetApi.postWidget(data.current as CreateWidgetDTO, jwtToken,
                            async (err) => {
                                alert(err);
                                setWidgets(await WidgetApi.getAllWidgets())
                            })
                        if (!postedData) {
                            return;
                        }

                        if (widgets) {
                            setWidgets(await WidgetApi.getAllWidgets());
                        }

                        setForceUpdate(!forceUpdate)
                    }} />
            </Stack>
        </>
    )
}
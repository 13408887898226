import { TableCell, TableRow } from "@mui/material";
import Image from "mui-image";
import { useContext } from "react";
import { ApiConnector, ProductDTO } from "../Api";
import { CafeTypography } from "../Components";
import { LanguageContext, translateByLocales } from "../utilities";
import { addZeroes } from "../utilities/addZeroes";

interface ProductItemProps {
    value: ProductDTO;
}

export const ProductItem = (props: ProductItemProps) => {
    const { value } = props;
    const { language } = useContext(LanguageContext);

    return (
            <TableRow>
                <TableCell sx={{
                    width: "auto"
                }}>
                    <Image style={{
                        aspectRatio: 1.0
                    }} src={ApiConnector.getImageURL(value.id, "Product")} width="64px" />
                </TableCell>
                <TableCell>
                   <CafeTypography fontSize={18}>{translateByLocales(value.name, language)}</CafeTypography>
                </TableCell>
                <TableCell>
                <CafeTypography sx={{
                    wordWrap: "break-word",
                    wordBreak: {
                        xs: "unset",
                        sm: "unset",
                        md: "break-word",
                        lg: "break-word",
                        xl: "break-word"
                    }
                }} fontSize={18}>{translateByLocales(value.description, language)}</CafeTypography>
                </TableCell>
                <TableCell>
                <CafeTypography fontSize={18}>{addZeroes(value.price.toString())}{"\u20AC"}/100g</CafeTypography>
                </TableCell>

            </TableRow>);
};

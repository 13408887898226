import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WidgetApi, WidgetDTO } from "../Api/Widgets";
import { MainPageGallery } from "../MainPageGallery";
import { Widget } from "./Widget";

export const MainHome = () => {
    const { t } = useTranslation();
    const [widgets, setWidgets] = useState<WidgetDTO[] | null>(null)
    useEffect(() => {
        const load = async () => {
            setWidgets(await WidgetApi.getWidgets("home"));
        }
        load();
    }, [])

    return (
        <>
            <MainPageGallery autoPlay
                showFullscreenButton={false}
                showNav={false}

                showPlayButton={false}
                showBullets items={[]} cssClass="" />
            <div style={{
                padding: 8
            }}>
                {widgets ? widgets.map(v => {
                    return <Widget widget={v} key={v.id} />
                }) : <CircularProgress color="primary" />}
            </div>

        </>
    )
}
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Box, IconButton, Stack, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiConnector, ProductDTO } from "../../Api";
import type { CategoryDTO, CreateCategoryDTO, CreateProductDTO } from "../../Api/ApiConnector";
import { CafeTypography, DataTable } from "../../Components";
import { InsertForm } from "../../Components/DataTable";
import { CategorySelect, LocalizableInput } from "../../Components/LocalizableInput";
import { AuthContext, LanguageContext, hasPermission, translateByLocales, typographyStyle } from "../../utilities";
import { ImageOrEmpty } from "./ImageOrEmpty";
import { UploadFile } from "./UploadFile";

export interface UploadFileProps {
    onChange: (data: File | null) => void;
    url?: string
}

export interface ImageOrEmptyProps {
    url: string | null
}

interface ToggleVisibilityProps<T extends {visible: boolean}> {
    value: T
    onVisibilityChange: (value: T) => Promise<void>
}

const ToggleVisibility = <T extends {visible: boolean}>(props: ToggleVisibilityProps<T>) => {
    const { value, onVisibilityChange } = props

    const [update, setUpdate] = useState(value.visible);

    return <IconButton onClick={async () => {
        value.visible = !update
        setUpdate(!update)

        await onVisibilityChange(value);
    }}>
        {value.visible ? <Visibility /> : <VisibilityOff />}
    </IconButton>
}

export const AdminRoom = () => {
    const formProduct = useRef<FormData>(new FormData())
    const formCategory = useRef<FormData>(new FormData())
    const formUpdateProduct = useRef<FormData>(new FormData())
    const formUpdateCategory = useRef<FormData>(new FormData())

    const { t } = useTranslation();
    const [products, setProducts] = useState<ProductDTO[] | null>(null);
    const [categories, setCategories] = useState<CategoryDTO[] | null>(null);
    const { language } = useContext(LanguageContext);
    const { jwtToken, roles, isRoot } = useContext(AuthContext);

    useEffect(() => {
        const load = async () => {
            if (hasPermission("SELECT", "products", roles, isRoot))
            setProducts(await ApiConnector.getProducts(jwtToken, err => alert(err)));
        
        if (hasPermission("SELECT", "categories", roles, isRoot))
            setCategories(await ApiConnector.getAllCategories(jwtToken, err => alert(err)));
        }

        load();
    }, [language])

    return (
        <>
            <Box>
                <CafeTypography sx={{ ...typographyStyle }} variant="h3">{t("system.adminRoom")}</CafeTypography>
            </Box>

            <Stack direction="column">
                {products && products.length > 0 ?
                    <DataTable<ProductDTO>
                        condition={hasPermission("SELECT", "products", roles, isRoot)}
                        initFilter={{
                            id: "",
                            category: [],
                            name: [],
                            description: [],
                            price: 0,
                            visible: false,
                        }}
                        // filterable

                        filters={{
                            category: {
                                component: (v, setV) => {
                                    return <CategorySelect multiple onChange={(id) => {
                                        if (!v) return;

                                        setV({...v, category: id});
                                    }} />
                                },
                                predicate: (v, filter) => {
                                    if (Array.isArray(v.category)) return false;
                                    if (!Array.isArray(filter.category)) return false;

                                    if (filter.category.length === 0 || !v.category) return true;
                                    return filter.category.includes(v.category)
                                    
                                }
                            }
                        }}

                        transformFields={{
                            description: v => {
                                return translateByLocales(v, language);
                            },
                            name: v => translateByLocales(v, language),
                            category: v => {
                                const name = categories?.find(x => x.id === v)?.name;
                                if (!name) return t("data.empty");

                                return translateByLocales(name, language)
                            }
                        }}
                        specialFields={{
                            thumbnail: (x) => {
                                return <ImageOrEmpty url={ApiConnector.getImageURL(x.id, "Product")} />
                            },
                            visible: x => {
                                return <ToggleVisibility value={x} onVisibilityChange={async (data) => {
                                    await ApiConnector.updateProduct(data.id, data, jwtToken, err => alert(err));
                                }} />
                            }
                        }}
                        schema={["name", "description", "price", "thumbnail", "category", "visible"]}
                        editFields={{
                            description: (data) => {
                                return <LocalizableInput multiline
                                    value={data.current?.description}
                                    onChange={(translation) => {
                                        if (!data.current) return;
                                        data.current.description = translation;
                                    }} />
                            },
                            name: (data) => {
                                return <LocalizableInput
                                    value={data.current?.name}
                                    onChange={(translation) => {
                                        if (!data.current) return;
                                        data.current.name = translation;
                                    }} />
                            },
                            price: (data) => {
                                return <TextField type="number"
                                    defaultValue={data.current ? data.current.price : 0}
                                    onChange={(e) => {
                                        if (!data.current) return;

                                        data.current.price = parseFloat(e.currentTarget.value);
                                    }} />
                            },
                            category: (data) => {
                                return <CategorySelect value={data.current ? data.current.category : null}
                                 onChange={(categoryId) => {
                                    if (!data?.current || Array.isArray(categoryId)) return;
                                    data.current.category = categoryId;
                                }} />
                            },
                            thumbnail: (data) => {
                                return <UploadFile
                                    url={ApiConnector.getImageURL(data.current?.id ?? "", "Product")}
                                    onChange={(file) => {
                                        if (!file) return;

                                       formUpdateProduct.current.set("image", file);
                                    }} />
                            },
                        }}

                        editable={hasPermission("UPDATE", "products", roles, isRoot)}
                        deletable={hasPermission("DELETE", "products", roles, isRoot)}

                        onUpdate={async data => {
                            setProducts(null);
                            await ApiConnector.updateProduct(data.id, data, jwtToken, err => alert(err));
                            if (formUpdateProduct.current.has("image")) {
                                await ApiConnector.putImage(`${data.id}/Image`, formUpdateProduct.current, jwtToken, "Product")
                            }
                            setProducts(await ApiConnector.getProducts(jwtToken, err => alert(err)));
                        }}

                        onDelete={async data => {
                            const ok = await ApiConnector.deleteProduct(data.id, jwtToken, err => {
                                alert(err);
                            })
                            if (ok) {
                                setProducts(await ApiConnector.getProducts(jwtToken, err => alert(err)));
                            }
                        }}


                        label={t("data.products")} data={products} /> : null}
               <InsertForm<ProductDTO>
                    condition={hasPermission("INSERT", "products", roles, isRoot)}
                    label={t("data.insert")}
                    schema={["name", "description", "price", "thumbnail", "category"]}
                    specialFields={
                        {
                            price: {
                                specialComponent: (props) => {
                                    return <TextField type="number" onChange={(e) => {
                                        props.handleChange(parseFloat(e.currentTarget.value));
                                    }} />
                                },
                            },
                            name: {
                                specialComponent: props => {
                                    return <LocalizableInput
                                        onChange={(translation) => {
                                            props.handleChange(translation);
                                        }}
                                    />
                                }
                            },
                            description: {
                                specialComponent: props => {
                                    return <LocalizableInput multiline
                                        onChange={(translation) => {
                                            props.handleChange(translation);
                                        }}
                                    />
                                }
                            },
                            thumbnail: {
                                specialComponent: props => {
                                    return <UploadFile onChange={(data) => {
                                        if (!data) {
                                            return;
                                        }
                                        formProduct.current.set("image", data);
                                    }} />
                                }
                            },
                            category: {
                                specialComponent: props => {
                                    return <CategorySelect onChange={(id) => {
                                        props.handleChange(id);
                                    }} />
                                }
                            }
                        }
                    }

                    onSubmit={async (data) => {
                        setProducts(null);
                        const postedData = await ApiConnector.postProduct(data.current as CreateProductDTO, jwtToken,
                            async (err) => {
                                alert(err);
                                setProducts(await ApiConnector.getProducts(jwtToken, err => alert(err)))
                            })
                        if (!postedData) {
                            return;
                        }
                        if (formProduct.current.has("image")) {
                            await ApiConnector.putImage(`${postedData.id}/Image`, formProduct.current, jwtToken, "Product",                         err => {
                                alert(err);
                            });
                        }

                        if (products) {
                            setProducts(await ApiConnector.getProducts(jwtToken, err => alert(err)));
                        }
                    }} />
            </Stack>
            <Stack direction="column">
                {categories && categories.length > 0 ?
                    <DataTable<CategoryDTO>
                        condition={hasPermission("SELECT", "categories", roles, isRoot)}
                        
                        initFilter={{
                            id: "",
                            name: [],
                            visible: false
                        }}

                        transformFields={{
                            name: v => translateByLocales(v, language),
                        }}
                        specialFields={{
                            thumbnail: x => {
                                return <ImageOrEmpty url={ApiConnector.getImageURL(x.id, "Category")} />
                            },
                            visible: x => {
                                return <ToggleVisibility value={x} onVisibilityChange={async (data) => {
                                    await ApiConnector.updateCategory(data.id, data, jwtToken, err => alert(err));
                                }} />   
                            }
                        }}
                        schema={["name", "thumbnail", "visible"]}
                        editFields={{
                            name: (data) => {
                                return <LocalizableInput
                                    value={data.current?.name}
                                    onChange={(translation) => {
                                        if (!data.current) return;
                                        data.current.name = translation;
                                    }} />
                            },
                            thumbnail: (data) => {
                                return <UploadFile
                                    url={ApiConnector.getImageURL(data.current?.id ?? "", "Category")}
                                    onChange={(file) => {
                                        if (!file || !data?.current) return;
                                        formUpdateCategory.current.set("image", file);
                                    }} />
                            },
                        }}

                        editable={hasPermission("UPDATE", "categories", roles, isRoot)}
                        deletable={hasPermission("DELETE", "categories", roles, isRoot)}

                        onUpdate={async (data) => {
                            setCategories(null);
                            await ApiConnector.updateCategory(data.id, data, jwtToken, err => alert(err))
                            await ApiConnector.putImage(`${data.id}/Image`, formUpdateCategory.current, jwtToken, "Category", err => alert(err));
                            setCategories(await ApiConnector.getAllCategories(jwtToken, err => alert(err)))
                        }}

                        onDelete={async (data) => {
                            const ok = await ApiConnector.deleteCategory(data.id, jwtToken, (err) => {
                                alert(err);
                            })
                            if (ok) {
                                setCategories(await ApiConnector.getAllCategories(jwtToken, err => alert(err)));
                            }
                        }}
                        label={t("data.categories")} data={categories} /> : null}
               <InsertForm<CategoryDTO>
                    condition={hasPermission("INSERT", "categories", roles, isRoot) }
                    label={t("data.insert")}
                    schema={["name", "thumbnail"]} specialFields={
                        {
                            name: {
                                specialComponent: (props) => {
                                    return <LocalizableInput
                                        onChange={(translation) => {
                                            props.handleChange(translation);
                                        }}
                                    />
                                }
                            },
                            thumbnail: {
                                specialComponent: (props) => {
                                    return <UploadFile onChange={(data) => {
                                        if (!data) {
                                            return;
                                        }
                                        formCategory.current.set("image", data);
                                    }} />
                                }
                            },
                        }
                    }

                    onSubmit={async (data) => {
                        setCategories(null);
                        const postedData = await ApiConnector.postCategory(data.current as CreateCategoryDTO, jwtToken,
                            async (err) => {
                                alert(err);
                                setCategories(await ApiConnector.getAllCategories(jwtToken, err => alert(err)))
                            })

                        if (!postedData) {
                            return;
                        }
                        await ApiConnector.putImage(`${postedData.id}/Image`, formCategory.current, jwtToken, "Category", 
                        err => {
                            alert(err);
                        })

                        if (categories) {
                            setCategories(await ApiConnector.getAllCategories(jwtToken, err => alert(err)));
                        }
                    }} />
            </Stack>

        </>
    )
}

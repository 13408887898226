import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { MutableRefObject, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CafeTypography } from "../CafeTypography";

export type Mapped<T, V> = {
    [key in keyof T]?: V
};

export interface FormField<T> {
    label: string;
    value: T;
}

type OnInsertSubmit<T> = (data: MutableRefObject<DataRow>) => Promise<void>;
type Schema<T> = (keyof T & string)[];

export interface HandleChangeForm {
    handleChange: (updatedData: any) => void;
}

export interface SpecialFormFields {
    specialComponent: (props: HandleChangeForm) => React.ReactNode;
}

export interface FillFormProps<T extends {} | DataRow> {
    label: string;
    schema: Schema<T>;
    specialFields: Mapped<T, SpecialFormFields>;
    onSubmit: OnInsertSubmit<T>;
    condition: boolean
}

type DataRow = {
    [key: string]: any
}

export interface InsertContextValue {
    data: MutableRefObject<DataRow> | null
}

export const InsertContext = React.createContext<InsertContextValue>({ data: null })

const Form = <T extends {}>(props: FillFormProps<T>) => {
    const { label, schema, specialFields, condition } = props;

    const { data } = useContext(InsertContext)
    const { t } = useTranslation();
    const handleChange = (value: any, key: keyof T & string) => {
        if (data && data.current) {
            data.current[key] = value
        }
    }
    
    if (condition === false) {
        return <></>
    }

    return <div style={{ overflowX: "auto" }}>
        <CafeTypography fontSize={24}>{label}</CafeTypography>
        <Table>
            <TableHead>
                <TableRow>
                    {schema.map((key) => {
                        return <TableCell>
                            <CafeTypography>
                                {t("data.fields." + key)}
                            </CafeTypography>
                        </TableCell>
                    })}
                </TableRow>
            </TableHead>
            <TableBody>

                <TableRow>
                    {schema.map((key) => {
                        return <TableCell key={`${key}_insert_fill_label`}>
                            {specialFields && key in specialFields && specialFields[key] ?
                                specialFields[key]!.specialComponent({
                                    handleChange: (updatedData: any) => {
                                        if (key in specialFields && specialFields[key]) {
                                            handleChange(updatedData, key);
                                        }
                                    }
                                })
                                :
                                <CafeTypography>{t("system.error")}</CafeTypography>}
                        </TableCell>
                    })}
                </TableRow>
            </TableBody>
            <Button onClick={() => {
                if (!data) return;
                const hook = async () => {
                    await props.onSubmit(data);
                }

                hook();
            }}><CafeTypography>{t("data.submit")}</CafeTypography></Button>
        </Table>
    </div>;
}

export const InsertForm = <T extends { id: string }>(props: FillFormProps<T>) => {
    const data = useRef({} as DataRow);

    return <InsertContext.Provider value={{ data }}>
        <Form {...props} />
    </InsertContext.Provider>
}
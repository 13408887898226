import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WidgetApi, WidgetDTO } from "../Api/Widgets";
import { Widget } from "./Widget";

export interface WidgetProps {
    widget: WidgetDTO
}

const Map = () => {
    return <div style={{width: "50%"}}>
        <iframe 
            width="100%" 
            height="600" 
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Ahtme%20mnt%2016,%20Ahtme,%2031025%20Ida-Viru%20maakond+(Kohvik%20S%C3%B6%C3%B6r%20Leiton)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
    </div>
}

export const Contacts = () => {
    const { t } = useTranslation();
    const [widgets, setWidgets] = useState<WidgetDTO[] | null>(null)
    useEffect(() => {
        const load = async () => {
            setWidgets(await WidgetApi.getWidgets("contact"));
        }
        load();
    }, [])

    return (
        <Stack direction="column">
            <Box>
                <Stack direction="row" flexWrap="wrap">
                    {widgets ? widgets.map(w => {
                        return <Widget widget={w} key={w.id} />
                    }) : null}
                </Stack>
            </Box>
            <Map />
        </Stack>
    )
}
import { useEffect, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';
import { ApiConnector } from './Api';
import "./Components/styles/Gallery.css";

interface GalleryProps extends ReactImageGalleryProps {
  cssClass?: string
}

export const MainPageGallery = (props: GalleryProps) => {
  const [images, setImages] = useState<ReactImageGalleryItem[]>([])

  useEffect(() => {
    const load = async () => {
      const imgs = await ApiConnector.getAllImages();
      setImages(imgs.map(x => {
        return {
          original: x,
          thumbnail: x,
          originalHeight: 568
        }
      }));
    }
    load();
  }, [])


  const { cssClass, items } = { ...props };

  return <ImageGallery {...props} additionalClass={cssClass}
    items={[...items, ...images]} />
}
import { MenuItem, Select, Stack, TextField } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ApiConnector, RoleDTO, UserDTO } from "../../Api/ApiConnector"
import { DataTable } from "../../Components"
import { InsertForm } from "../../Components/DataTable"
import { AuthContext, hasPermission } from "../../utilities"
import { RoleRoom } from "./RoleRoom"

interface StringSelectorProps {
    value?: string[]
    onChange: (roles: string[]) => void
}

export interface TableSelector {
    value?: string
    onChange: (table: string) => void
}


export const TableSelector = (props: TableSelector) => {
    const tables = ["gallery_assets", "users", "categories", "products", "widgets", "permissions"]

    const { value, onChange } = props;
    const [selectedTable, setSelectedTable] = useState<string>(!value ? "" : value)

    return <Select
    value={selectedTable}
    onChange={(e) => {
        setSelectedTable(e.target.value)

        if (!onChange) return;

        onChange(e.target.value);
    }}>
        {tables.map(r => {
            return <MenuItem
            key={r} 
            value={r}>
                {r}
            </MenuItem>
        })}
    </Select>
}


export const PermissionSelector = (props: StringSelectorProps) => {
    const perms = ["SELECT", "UPDATE", "DELETE", "INSERT"]

    const { value, onChange } = props;
    const [selectedRoles, setSelectedRoles] = useState<string[]>(!value ? [] : value)
    return <Select
    multiple 
    value={selectedRoles}
    onChange={(e) => {
        if (!Array.isArray(e.target.value)) {
            return;
        }

        setSelectedRoles(e.target.value)

        if (!onChange) return;

        onChange(e.target.value);
    }}>
        {perms.map(r => {
            return <MenuItem
            key={r} 
            value={r}>
                {r}
            </MenuItem>
        })}
    </Select>
}

const RoleSelector = (props: StringSelectorProps) => {
    const { value, onChange } = props;
    const [roles, setRoles] = useState<string[]>([])
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const { jwtToken } = useContext(AuthContext);
    useEffect(() => {
        const load = async () => {
            let ok = true

            const r = await ApiConnector.getTs<RoleDTO>(jwtToken, "Role", "", () => ok = false)
            if (!ok) {
                return
            }
            setRoles(r.map(x => x.name));
        }

        load();
    }, [])

    return <Select
    multiple 
    defaultValue={value}
    value={selectedRoles}
    onChange={(e) => {
        if (!Array.isArray(e.target.value)) {
            return;
        }

        setSelectedRoles(e.target.value)

        if (!onChange) return;

        onChange(e.target.value);
    }}>
        {roles.map(r => {
            return <MenuItem
            key={r} 
            value={r}>
                {r}
            </MenuItem>
        })}
    </Select>
}

export const UserRoom = () => {
    const [users, setUsers] = useState<UserDTO[]>([])
    const { jwtToken, roles, isRoot } = useContext(AuthContext);
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {
            setUsers(await ApiConnector.getTs(jwtToken, "User", "", err => alert(err)))
        }
        load();
    }, [])

    return <Stack>
        <DataTable data={users}
            label="USERS"
            schema={["username", "roles"]}

            condition={hasPermission("SELECT", "users", roles, isRoot)}
            
            editable={hasPermission("UPDATE", "users", roles, isRoot)}
            deletable={hasPermission("DELETE", "users", roles, isRoot)}

            transformFields={{
                roles: (x) => {
                    return x.join(" ")
                }
            }}

            editFields={{
                username: x => {
                    return <TextField defaultValue={x.current?.username} onChange={(e) => {
                        if (!x.current) return;
                        x.current.username = e.target.value;
                    }}/>
                },
                roles: x => {
                    return <RoleSelector onChange={r => {
                        if (!x.current) return;
                        x.current.roles = r;
                    }} value={x.current?.roles} />
                },
                password: x => {
                    return <TextField autoComplete="new-password" type="password" onChange={(e) => {
                        if (!x.current) return
                        
                        x.current.password = e.target.value;
                    }} />
                }
            }}

            onDelete={async (data) => {
                setUsers([]);
                await ApiConnector.delete(data.id, jwtToken, "User", err => alert(err));
                setUsers(await ApiConnector.getTs(jwtToken, "User", "", err => alert(err)));
            }}

            onUpdate={async (data)=> {
                await ApiConnector.updateT<UserDTO>(data.id, data, jwtToken, "User", "", err => alert(err));
            }}
        />
        <InsertForm<UserDTO> 
        condition={hasPermission("INSERT", "users", roles, isRoot)}

        label={t("data.createUser")} 
        schema={["username", "password", "roles"]}
        specialFields={{
            username: {
                specialComponent: props => {
                    return <TextField autoComplete="new-password" onChange={(e) => {
                        props.handleChange(e.target.value);
                    }} />
                }
            },
            password: {
                specialComponent: props => {
                    return <TextField autoComplete="new-password" type="password" onChange={(e) => {
                        props.handleChange(e.target.value);
                    }} />
                }
            },
            roles: {
                specialComponent: props => {
                    return <RoleSelector onChange={r => {
                        props.handleChange(r);
                    }} />
                }
            }
        }}
        onSubmit={async (data) => {
            if (!data.current) return;

            setUsers([])
            await ApiConnector.createT(data.current, jwtToken, "User", err => alert(err));
            setUsers(await ApiConnector.getTs(jwtToken, "User", "", err => alert(err)))
        }}
        />
        <RoleRoom />
    </Stack>
}


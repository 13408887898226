import { AccessTime, Home, Phone } from "@material-ui/icons";
import { AppBar, CircularProgress, IconButton, Stack } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WidgetApi, WidgetDTO } from "../Api/Widgets";
import { BackgroundImage } from "../BackgroundImage";
import { MainHome } from "../Pages/Home";
import '../i18n/config';
import { AuthContext, LanguageContext, Tab, TabContext, translateByLocales } from "../utilities";
import { CafeTypography } from "./CafeTypography";
import { Header } from "./Header";
import "./styles/App.css";

export const App = () => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [phoneNumber, setPhoneNumber] = useState<WidgetDTO[] | null>(null);
  const [accessTime, setAccessTime] = useState<WidgetDTO[] | null>(null);

  const { roles } = useContext(AuthContext);

  useEffect(()=> {
    const load = async () => {
      const widgetsPhone = await WidgetApi.getWidgets("header_phone");

      setPhoneNumber(widgetsPhone);

      const widgetsTime = await WidgetApi.getWidgets("header_time");

      setAccessTime(widgetsTime);
    };

    load();
  }, [language, roles])

  return (
    <>
      <CssBaseline />
      <Stack sx={{
        backgroundColor: "#c9c3a9"
      }}>
        <TabContext.Consumer>
          {tab => {
            if (tab.currentTab?.getTag() !== "home") {
              return null;
            }

            return <BackgroundImage source="bg" />
          }}
        </TabContext.Consumer>
      </Stack>
      <Header createTabs={false} backgroundColor="#401C21">
        <IconButton onClick={() => {
            const url = "https://kjvhk.ee";
            window.open(url);
          }}>
            <Home htmlColor="white" />
            <CafeTypography color="white" fontSize="12pt">{t("app.ourHome")}</CafeTypography>
        </IconButton>

        <IconButton>
          <Phone htmlColor="white" />
          {phoneNumber ? phoneNumber.map(x => {
            return <CafeTypography key={x.id} fontSize="12pt" color="white">
              {translateByLocales(x.title, language)}
            </CafeTypography>
          }
          
          ) : <CircularProgress color="secondary" />}
        </IconButton>
        <IconButton>
          <AccessTime htmlColor="white" />
          {accessTime ? accessTime.map(x => {
            return <CafeTypography key={x.id} fontSize="12pt" color="white">
              {translateByLocales(x.title, language)}
            </CafeTypography>
          }
          
          ) : <CircularProgress color="secondary" />}
        </IconButton>
      </Header>
      <AppBar position="sticky" sx={{
        display: "inline-flex",
      }}>
      <Header createTabs languages />
      </AppBar>
      <div className="container-box">
        <TabContext.Consumer>
          {tab => {
            const { currentTab, setCurrentTab } = tab;
            if (!currentTab && setCurrentTab) {
              setCurrentTab(new Tab(t("app.home.title"), "home", <div>
                <MainHome />
              </div>))
            }
            if (!currentTab) {
              return;
            }
            return currentTab.getComponent();
          }}
        </TabContext.Consumer>
      </div>
    </>
  );
}

export default App;
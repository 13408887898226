import React from "react";
import { RoleDTO } from "../Api/ApiConnector";

export interface AuthContextValue {
    isLogin: boolean
    isRoot: boolean
    setIsRoot: React.Dispatch<React.SetStateAction<boolean>> | null
    setLogin: React.Dispatch<React.SetStateAction<boolean>> | null
    jwtToken: string
    setJwtToken: React.Dispatch<React.SetStateAction<string>> | null
    roles: RoleDTO[]
    setRoles: React.Dispatch<React.SetStateAction<RoleDTO[]>> | null
}

export const AuthContext = React.createContext<AuthContextValue>({
    isLogin: false,
    setLogin: null,
    jwtToken: "",
    setJwtToken: null,
    roles: [],
    setRoles: null,
    isRoot: false,
    setIsRoot: null
})
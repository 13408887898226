import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { App, SignIn } from "./Components";
import { ContextProviders } from "./Components/ContextProviders";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ContextProviders>
      <BrowserRouter>
        <Routes>
        <Route index element={<App />} />
          <Route path="login" element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    </ContextProviders>
  </React.StrictMode>
);

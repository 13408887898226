import { Button, TypographyProps } from "@mui/material";
import React, { useContext } from "react";
import { NavigateFunction } from "react-router-dom";
import { CafeTypography } from "../Components";

export interface TabContextValue {
    currentTab: Tab | null
    setCurrentTab: React.Dispatch<React.SetStateAction<Tab | null>> | null
}

export const TabContext = React.createContext<TabContextValue>({
    currentTab: null,
    setCurrentTab: null
})

export class Tab {
    private label: string;
    private component: React.JSX.Element;
    private tag: string

    constructor(label: string, tag: string, component: React.JSX.Element) {
        this.label = label;
        this.component = component;
        this.tag = tag;
    }

    public getLabel(): string {
        return this.label;
    }

    public getTag(): string {
        return this.tag;
    }

    public getComponent(): React.JSX.Element {
        return this.component;
    }
}

export type OnTabClickHandler = (tab: Tab) => void;

export class TabCreator {
    private tabs: Tab[] = [];

    public static createTab(): TabCreator {
        return new TabCreator();
    }

    public addTab(label: string, tag: string, component: React.JSX.Element): TabCreator {
        this.tabs.push(new Tab(label, tag, component));
        return this;
    }

    public addTabConditional(condition: boolean, label: string, tag: string, component: React.JSX.Element): TabCreator {
        if (condition) {
            return this.addTab(label, tag, component);
        }
        return this;
    }

    public finish(nav: NavigateFunction, typographyProps?: TypographyProps): React.JSX.Element {
        if (this.tabs.length === 0) {
            return <></>
        }
        const ResultingComponent = <>
                {this.tabs.map(tab => {
                    const context = useContext(TabContext);
                    return <Button onClick={()=> {
                        if (!context.setCurrentTab) throw new Error("Set Current Tab is null");
                        context.setCurrentTab(tab);
                        nav("/");
                    }}>
                        <CafeTypography fontSize="12pt" variant="h6" {...typographyProps}>{tab.getLabel()}</CafeTypography>
                    </Button>
                })}
            </>;

        return ResultingComponent;
    }
}
import { Box, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useContext } from "react";
import { CafeTypography } from "../Components";
import { LanguageContext, translateByLocales } from "../utilities";
import { WidgetProps } from "./Contacts";


export const Widget = (props: WidgetProps) => {
    const { widget } = props;
    const { language } = useContext(LanguageContext);
    return (
        <Box width="100%" height="100%" padding="16px">
            <Card>
                <CardHeader sx={{
                    backgroundColor: "#401C21",
                    color: "white"
                }} title={<CafeTypography>{translateByLocales(widget.title, language)}</CafeTypography>} />
                <CardContent sx={{
                    backgroundColor: "#D7C6B5"
                }}>
                    <CafeTypography sx={{
                        whiteSpace: "pre-wrap"
                    }} color="#401C21">{translateByLocales(widget.content, language)}</CafeTypography>
                    <Stack direction="row" flexWrap="wrap">
                        {widget.children ? widget.children.map(child => {
                            return <Widget widget={child} key={child.id} />;
                        }) : null}
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
};

import React from "react";
import { Languages } from "../Api";

interface LanguageContextValue {
    language: Languages
    setLanguage: React.Dispatch<React.SetStateAction<Languages>> | null
}

export const LanguageContext = React.createContext<LanguageContextValue>({
    language: "ee",
    setLanguage: null
});
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { IconButton, Stack } from "@mui/material";
import { useContext } from "react";
import ReactCountryFlag from "react-country-flag";
import { LanguageContext } from "../utilities";

interface LanguageSelectProps {
  style?: CSSProperties
}

export const LanguageSelect = (props: LanguageSelectProps) => {
  const { setLanguage } = useContext(LanguageContext);
  return <Stack direction="row" style={props.style}>
    <IconButton onClick={() => {
      if (!setLanguage) return;
      setLanguage("ee");
    }} >
      <ReactCountryFlag style={{
        filter: "drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5))"
      }} svg countryCode="ee" />
    </IconButton>


    <IconButton onClick={() => {
      if (!setLanguage) return;
      setLanguage("ru");
    }} >
      <ReactCountryFlag style={{
        filter: "drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5))"
      }} svg countryCode="ru" />
    </IconButton>


    <IconButton onClick={() => {
      if (!setLanguage) return;
      setLanguage("en");
    }} >
      <ReactCountryFlag style={{
        filter: "drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.5))"
      }} svg countryCode="gb" />
    </IconButton>
  </Stack>
};

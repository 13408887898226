import { ApiConnector, LocalizableDTO } from "./ApiConnector";

export interface WidgetDTO {
    id: string
    tag: string | string[]
    title: LocalizableDTO[];
    content: LocalizableDTO[];
    parentId: string | null | string[]
    children: WidgetDTO[] | null
}

export interface CreateWidgetDTO {
    tag: string
    title: LocalizableDTO[];
    content: LocalizableDTO[];
    parent: string | null
}

export class WidgetApi {
    private static readonly requestInit: RequestInit = {
        mode: "cors"
    }

    private static getParams(method?: string): string {
        if (method) {
            return `${ApiConnector.url}api/Widget/${method}`
        } else {
            return `${ApiConnector.url}api/Widget`
        }
    }

    public static async getWidgets(tag: string): Promise<WidgetDTO[]> {
        try {
            const widgets = (await fetch(this.getParams(`Tag/${tag}`), WidgetApi.requestInit))
                .json() as Promise<WidgetDTO[]>;
            return widgets;
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    public static async getAllWidgets(): Promise<WidgetDTO[]> {
        try {
            const widgets = (await fetch(this.getParams(""), WidgetApi.requestInit))
                .json() as Promise<WidgetDTO[]>;
            return widgets;
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    public static async deleteWidget(id: string, token: string, onError?: (error: string) => void): Promise<boolean> {
        const resp = await fetch(this.getParams(id), {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            }
        })
        if (!resp.ok) {
            if (onError) {
                onError(await resp.text())
            }
            return false;
        }
        return true;
    }

    public static async updateWidget(id: string, widget: WidgetDTO, token: string, onError?: (error: string) => void): Promise<WidgetDTO | null> {
        const resp = await fetch(this.getParams(id), {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(widget)
        });

        if (resp.ok) {
            return await resp.json() as Promise<WidgetDTO>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

    public static async postWidget(widget: CreateWidgetDTO, token: string, onError?: (error: string) => void): Promise<WidgetDTO | null> {
        const resp = await fetch(this.getParams(""), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(widget)
        });
        if (resp.ok) {
            return await resp.json() as Promise<WidgetDTO>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

}
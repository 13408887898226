import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import eeEE from './locales/ee-EE.json'
import enGB from './locales/en-GB.json'
import ruRU from './locales/ru-RU.json'

const defaultLanguage = 'ee-EE'

export const defaultNamespace = 'default'

export const resources = {
    'ee': {
        [defaultNamespace]: eeEE,
    },
    'ru': {
        [defaultNamespace]: ruRU,
    },
    'en': {
        [defaultNamespace]: enGB
    }
    
}

i18n.use(initReactI18next).init({
    defaultNS: defaultNamespace,
    ns: [defaultNamespace],
    resources,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
        escapeValue: false,
    },
})


import { ThemeProvider } from "@mui/material/styles";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { ApiConnector, Languages, ProductDTO } from "../Api";
import { RoleDTO } from "../Api/ApiConnector";
import '../i18n/config';
import { AuthContext, LanguageContext, Tab, TabContext, theme } from "../utilities";
import "./styles/App.css";

interface Props {
  children: React.ReactNode;
}

interface CartContextValue {
  cart: ProductDTO[]
  setCart: React.Dispatch<React.SetStateAction<ProductDTO[]>> | null
}

export const CartContext = React.createContext<CartContextValue>({
  cart: [],
  setCart: null
})

export const ContextProviders = ({ children }: Props) => {
  const [currentTab, setCurrentTab] = useState<Tab | null>(null)
  const [language, setLanguage] = useState<Languages>("ee");
  const [cart, setCart] = useState<ProductDTO[]>([])
  const lsToken = localStorage.getItem("token")

  const [isLogin, setLogin] = useState(lsToken != null);
  const [jwtToken, setJwtToken] = useState(lsToken ? lsToken : "");
  const [roles, setRoles] = useState<RoleDTO[]>([])
  const [isRoot, setIsRoot] = useState(false);

  useEffect(() => {
    const auth = async () => {
      if (lsToken) {
        if (!(await ApiConnector.checkLogin(lsToken))) {
          setLogin(false);
          setJwtToken("");
          return;
        }

        setRoles(await ApiConnector.getTs<RoleDTO>(jwtToken, "User", "Role"));
        setIsRoot(await ApiConnector.isRoot(jwtToken));

      }
    }
    auth();
  }, [])


  return (
    <CartContext.Provider value={{ cart, setCart }}>
      <ThemeProvider theme={theme}>
        <TabContext.Provider value={{ currentTab, setCurrentTab }}>
          <AuthContext.Provider value={{
            isLogin, setLogin, jwtToken, setJwtToken, roles, setRoles, isRoot, setIsRoot
          }}>
            <LanguageContext.Provider value={{
              language, setLanguage: v => {
                i18next.changeLanguage(v.toString());
                setLanguage(v)
              }
            }}>
              {children}
            </LanguageContext.Provider>
          </AuthContext.Provider>
        </TabContext.Provider>
      </ThemeProvider>
    </CartContext.Provider>
  );
}

import { FileCopy } from "@material-ui/icons";
import { Button, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiConnector, CategoryDTO, Languages, type LocalizableDTO } from "../Api/ApiConnector";
import { WidgetApi, WidgetDTO } from "../Api/Widgets";
import { LanguageContext, translateByLocales } from "../utilities";
import { findIndexByLanguage } from "../utilities/translateByLocales";
import { CafeTypography } from "./CafeTypography";

interface LocalizableInputProps {
    onChange: (translation: LocalizableDTO[]) => void;
    value?: LocalizableDTO[] | null;
    multiline?: boolean
    enableCopy?: boolean
}

export const LocalizableInput = (props: LocalizableInputProps) => {

    const rotation: Languages[] = ["ee", "ru", "en"];

    const { onChange, value, multiline, enableCopy } = props;
    const [translation, setTranslation] = useState<LocalizableDTO[]>(value ? [...value] : [
        { language: "ee", value: "" },
        { language: "ru", value: "" },
        { language: "en", value: "" }
    ]);
    const [index, setIndex] = useState(0);
    const { t } = useTranslation();
    useEffect(() => {
        onChange(translation);
    }, [translation]);

    const findStringValueByIndex = (): string => {
        const lang = rotation[index];

        console.log(lang);
        console.log(translation);

        const i = translation.findIndex(x => x.language.toLowerCase() === lang.toLowerCase());
        return translation[i].value;
    }

    return <Stack direction="row">
        <TextField multiline={multiline} label={rotation[index].toUpperCase()} value={translateByLocales(translation, rotation[index])} onChange={(e) => {
            setTranslation([
                {
                    language: rotation[index],
                    value: e.currentTarget.value
                },
                ...rotation.filter(x => x !== rotation[index]).map(x => translation[findIndexByLanguage(translation, x)])
            ]);
        }} />
        <Button onClick={() => {
            setIndex((index + 1) % rotation.length);
        }}>
            <CafeTypography>
                {t("system.switchLanguage")}
            </CafeTypography>
        </Button>
        {enableCopy ? <Button onClick={() => {
            const stringValue = findStringValueByIndex();
            const mapped = translation.map((v) => {
                v.value = stringValue
                return v;
            });

            setTranslation(mapped)
        }}>
            <FileCopy />
        </Button> : null}
    </Stack>;
};

interface CategorySelectProps {
    onChange: (id: string | (string | null)[] | null) => void;
    value?: string | null | (string | null)[]
    multiple?: boolean
}

export const CategorySelect = (props: CategorySelectProps) => {
    const { value, onChange, multiple } = props;
    const [categoryId, setCategoryId] = useState<string | null | (string | null)[]>(value ? value : null)
    const { language } = useContext(LanguageContext);
    const [categories, setCategories] = useState<CategoryDTO[] | null>(null)
    const { t } = useTranslation();
    useEffect(() => {
        const load = async () => {
            setCategories(await ApiConnector.getCategories());
        }
        load();
        onChange(categoryId);
    }, [categoryId])


    return <Select
        multiple={multiple}

        defaultValue={multiple && !props.value ? [] : props.value}
        sx={{
            height: '2.5rem',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white'
            },
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
        }}

        onChange={event => {
            if (!setCategoryId) return;

            setCategoryId(event.target.value as string);

        }}
    >
        {categories ? categories.map((v) => {
            return <MenuItem value={v.id} key={v.id}>
                {translateByLocales(v.name, language)}
            </MenuItem>
        }) : null}
    </Select>
}

interface ParentWidgetSelectProps {
    onChange: (id: string | null | string[]) => void;
    update: boolean
    value?: string | string[] | null
    multiple?: boolean
}

export const ParentWidgetSelect = (props: ParentWidgetSelectProps) => {
    const { value, onChange, update, multiple } = props;

    const def = multiple ? [] as string[] : ""

    const { t } = useTranslation();
    const [parentId, setParentId] = useState<string | null | string[]>(value ? value : def)
    const { language } = useContext(LanguageContext);
    const [widgets, setWidgets] = useState<WidgetDTO[] | null>(null)

    useEffect(() => {
        const load = async () => setWidgets(await WidgetApi.getAllWidgets());
        load();
        onChange(parentId);
    }, [parentId, update])

    return <Select
        defaultValue={value ? value : def}
        sx={{
            height: '2.5rem',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white'
            },
            '& .MuiSvgIcon-root': {
                color: 'white'
            },
            float: "right"
        }}
        multiple={multiple}
        onChange={event => {
            if (!setParentId) return;

            setParentId(event.target.value as string);
        }}
    >
        {widgets ? widgets.map((v) => {
            return <MenuItem value={v.id} key={v.id}>
                {translateByLocales(v.title, language)}
            </MenuItem>
        }) : null}
        <MenuItem value="">
            {t("data.empty")}
        </MenuItem>
    </Select>
}
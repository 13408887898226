import { createTheme } from "@mui/material"

export const theme = createTheme({
    palette: {
        background: {
            default: "#D7C6B5",
            paper: "#D7C6B5"
        },
        primary: {
            main: "#401C21",
            dark: "rgaba(0.5,0.3,0.2,1.0)",
            contrastText: "white",
        },
        divider: "#D7C6B5",
        secondary: {
            main: "#a49279"
        },
    },
    typography: {
        fontFamily: "'Lora', serif",
        body1: {
            fontSize: "18pt",
        }
    },
})

import { Stack, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiConnector, RoleDTO } from "../../Api/ApiConnector";
import { DataTable } from "../../Components";
import { InsertForm } from "../../Components/DataTable";
import { AuthContext, hasPermission } from "../../utilities";
import { PermissionSelector, TableSelector } from "./UserRoom";


export const RoleRoom = () => {
    const [rolesData, setRolesData] = useState<RoleDTO[]>([]);
    const { jwtToken, isRoot, roles } = useContext(AuthContext);
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {
            if (hasPermission("SELECT", "permissions", roles, isRoot))
            setRolesData(await ApiConnector.getTs(jwtToken, "Role", "", err => alert(err)));
        };
        load();
    }, []);

    return <Stack>
        <DataTable data={rolesData}
            condition={hasPermission("SELECT", "permissions", roles, isRoot)}

            label="ROLES"
            schema={["name", "permissions", "table"]}

            editable={hasPermission("UPDATE", "permissions", roles, isRoot)}
            deletable={hasPermission("DELETE", "permissions", roles, isRoot)}

            editFields={{
                name: x => {
                    return <TextField defaultValue={x.current?.name} onChange={(e) => {
                        if (!x.current) return;

                        x.current.name = e.target.value;
                    }} />;
                },
                permissions: x => {
                    return <PermissionSelector value={x.current?.permissions} onChange={r => {
                        if (!x.current) return;

                        x.current.permissions = r;
                    }} />;
                },
                table: x => {
                    return <TableSelector value={x.current?.table} onChange={(v) => {
                        if (!x.current) return;

                        x.current.table = v;
                    }} />;
                }
            }}

            transformFields={{
                permissions: x => {
                    return x.join(" ");
                }
            }}

            onDelete={async (data) => {
                setRolesData([]);
                await ApiConnector.delete(data.id, jwtToken, "Role", err => alert(err));
                setRolesData(await ApiConnector.getTs(jwtToken, "Role", "", err => alert(err)));
            }}

            onUpdate={async (data) => {
                await ApiConnector.updateT<RoleDTO>(data.id, data, jwtToken, "Role", "", err => alert(err));
            }} />
        <InsertForm<RoleDTO>
            condition={hasPermission("INSERT", "permissions", roles, isRoot)}

            label={t("data.createRole")}
            schema={["name", "table", "permissions"]}
            specialFields={{
                name: {
                    specialComponent: props => {
                        return <TextField onChange={(e) => {
                            props.handleChange(e.target.value);
                        }} />;
                    }
                },
                table: {
                    specialComponent: props => {
                        return <TableSelector onChange={(v) => {
                            props.handleChange(v);
                        }} />;
                    }
                },
                permissions: {
                    specialComponent: props => {
                        return <PermissionSelector onChange={r => {
                            props.handleChange(r);
                        }} />;
                    }
                }
            }}
            onSubmit={async (data) => {
                if (!data.current) return;
                setRolesData([]);
                await ApiConnector.createT(data.current, jwtToken, "Role", err => alert(err));
                setRolesData(await ApiConnector.getTs(jwtToken, "Role", "", err => alert(err)));
            }} />
    </Stack>;

};

import { Home } from "@material-ui/icons";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WidgetApi, WidgetDTO } from "../Api/Widgets";
import { CafeTypography } from "../Components";
import { Widget } from "./Widget";

export const AboutUs = () => {
  const { t } = useTranslation();
  const [widgets, setWidgets] = useState<WidgetDTO[] | null>(null)
  useEffect(() => {
      const load = async () => {
          setWidgets(await WidgetApi.getWidgets("about"));
      }
      load();
  }, [])

  return (
    <Stack direction="column">
      <Box>
        <Stack direction="row" flexWrap="wrap">
          {widgets ? widgets.map(w => {
            return <Widget widget={w} key={w.id} />
          }) : <CircularProgress />}
        </Stack>
        <Button onClick={() => {
            const url = "https://kjvhk.ee";
            window.open(url);
          }}>
            <Home />
            <CafeTypography fontSize="12pt">{t("app.ourHome")}</CafeTypography>
          </Button>
      </Box>
    </Stack>
  )
}
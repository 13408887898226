import { Box } from "@mui/material";
import { Images, importedImage } from "./Images";

interface BackgroundImageProps {
    source: importedImage
    video?: boolean
}

export const BackgroundImage = (props: BackgroundImageProps) => {
    const { source, video } = { ...props };
    return (
        !video ? <Box sx={{
            backgroundImage: `url("${Images[source]}")`,
            backgroundPosition: "left bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            height: "100vh",
            width: "100%",
            minHeight: "100%",
            zIndex: 25,
            transform: "translateZ(-4px)"
        }}>
        </Box> :
            <video height={"50%"}
                style={{
                    objectFit: "contain",
                    height: "100vh",
                    top: 0,
                    left: 0,
                    zIndex: 25,
                    transform: "translateZ(-4px)"
                }} muted autoPlay>
                <source src={Images[source]} />
            </video>
    )
}
import { Box, Stack } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiConnector } from "../../Api";
import { CafeTypography, DataTable } from "../../Components";
import { InsertForm } from "../../Components/DataTable";
import { AuthContext, hasPermission, typographyStyle } from "../../utilities";
import { ImageOrEmpty } from "./ImageOrEmpty";
import { UploadFile } from "./UploadFile";

interface ImageAsset {
    id: string
    imageUrl: string
}

export const GalleryRoom = () => {
    const formImage = useRef<FormData>(new FormData())
    const formUpdateImage = useRef<FormData>(new FormData())

    const mapToAsset = (data: string[]): ImageAsset[] => {
        return data.map(x => {
            const splitted = x.split("/")
            const id = splitted[splitted.length - 1];
            return {
                id: id,
                imageUrl: x
            } as ImageAsset
        })
    }

    const [images, setImages] = useState<ImageAsset[]>([])

    const { t } = useTranslation();
    const { jwtToken, roles, isRoot } = useContext(AuthContext);

    useEffect(() => {
        const load = async () => {
            const imgs = await ApiConnector.getAllImages()
            const assets = mapToAsset(imgs);

            setImages(assets);
        }

        load();
    }, [])

    return (
        <>
            <Box>
                <CafeTypography sx={{ ...typographyStyle }} variant="h3">{t("system.adminRoom")}/{t("app.gallery")}</CafeTypography>
            </Box>

            <Stack direction="column">
                 <DataTable 
                condition={hasPermission("SELECT", "gallery_assets", roles, isRoot)}
                data={images}
                 label={t("app.gallery")} 
                 schema={["imageUrl"]}
                 specialFields={{
                    imageUrl: x => <ImageOrEmpty url={ApiConnector.getImageURL(x.id, "Gallery")+"?"+Date.now()} />
                 }}

                 initFilter={{
                    id: "",
                    imageUrl: ""
                 }}
                 
                 editable={hasPermission("UPDATE", "gallery_assets", roles, isRoot)}
                 deletable={hasPermission("DELETE", "gallery_assets", roles, isRoot)}

                 onUpdate={async (data)=> {
                    if (!formUpdateImage.current.has("image")) {
                        alert("no image provided");
                        return;
                    }
                    setImages([]);
                    ApiConnector.putImage(data.id, formUpdateImage.current, jwtToken, "Gallery", err => alert(err));
                    setImages(mapToAsset(await ApiConnector.getAllImages()))

                 }}
                 onDelete={async (data)=> {
                    setImages([]);
                    await ApiConnector.deleteImage(data.id, jwtToken, "Gallery");
                    setImages(mapToAsset(await ApiConnector.getAllImages()));
                 }}

                 editFields={{
                    imageUrl: (props) => {
                        return <UploadFile onChange={file => {
                            if (!file) return;
                            formUpdateImage.current.set("image", file)
                        }} />
                    }
                 }}
                 />
                <InsertForm<ImageAsset>
                    label={t("data.insert")}
                    schema={["imageUrl"]}
                    condition={hasPermission("INSERT", "gallery_assets", roles, isRoot)}
                    specialFields={
                        {
                            imageUrl: {
                                specialComponent: props => {
                                    return <UploadFile onChange={(data) => {
                                        if (!data) {
                                            return;
                                        }
                                        formImage.current.set("image", data);
                                    }} />
                                }
                            }
                        }
                    }

                    onSubmit={async (data) => {
                        if (!formImage.current.has("image")) {
                            alert("No image provided");
                        }
                        setImages([])
                        await ApiConnector.postImage(formImage.current, jwtToken, "Gallery", err => alert(err))
                        const newImages = mapToAsset(await ApiConnector.getAllImages())
                        setImages(newImages);
                        
                    }} />
            </Stack>

        </>
    )
}

import { ArrowBack } from "@material-ui/icons";
import { ButtonBase, CircularProgress, Fade, IconButton, Table, TableBody } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ApiConnector } from "../Api";
import { CategoryDTO, ProductDTO } from "../Api/ApiConnector";
import { WidgetApi, WidgetDTO } from "../Api/Widgets";
import { CafeTypography } from "../Components";
import { LanguageContext, Tab, TabContext, translateByLocales } from "../utilities";
import { ProductItem } from "./ProductItem";
import { Widget } from "./Widget";


interface MenuPageProps {
    categoryId: string;
}

const MenuPage = (props: MenuPageProps) => {
    const { categoryId } = props;
    const [products, setProducts] = useState<ProductDTO[] | null>(null);
    const { language } = useContext(LanguageContext);
    const { setCurrentTab } = useContext(TabContext);
    useEffect(() => {
        const load = async () => {
            setProducts(await ApiConnector.getProductsByCategory(categoryId));
        }
        load();
    }, [language]);

    return (<>

        <IconButton onClick={() => {
            if (!setCurrentTab) return;
            setCurrentTab(new Tab("","cat", <MenuCategories />))
        }}>
            <ArrowBack />
        </IconButton>
        {products ?
            <Fade in>
                <div style={{
                    margin: "0 10% 0 10%",
                    overflowX: "auto"
                }}>
                    <Table sx={{
                        whiteSpace: "nowrap",
                        width: "100%",
                        tableLayout: "auto"
                    }}>
                        <TableBody>
                        {products.map(product => {
                            return <ProductItem key={product.id} value={product} />
                        })}
                        </TableBody>
                    </Table>
                </div>
            </Fade> :
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress color="secondary" />
            </div>}
    </>)
}

export const MenuCategories = () => {
    const [categories, setCategories] = useState<CategoryDTO[] | null>(null);
    const [widgets, setWidgets] = useState<WidgetDTO[]>([]);
    const { language } = useContext(LanguageContext);
    const { setCurrentTab } = useContext(TabContext);
    useEffect(() => {
        const load = async () => {
            const cats = await ApiConnector.getCategories()
            setWidgets(await WidgetApi.getWidgets("empty_menu"));
            setCategories(cats);
        }
        load();
    }, []);

    return (!categories ?
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
        </div> : categories.length !== 0 ?
        <Fade in>
            <div>
                {categories.map(category => {
                    return (<ButtonBase key={category.id} sx={{
                        backgroundImage: `url("${ApiConnector.getImageURL(category.id, "Category")}")`,
                        width: "100%",
                        borderRadius: "24px",
                        height: 168,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        marginTop: "8px",
                        marginBottom: "8px",
                    }} onClick={() => {
                        if (!setCurrentTab) return;
                        setCurrentTab(new Tab("", "", <MenuPage categoryId={category.id} />))
                    }}>
                        <div style={{
                            borderRadius: "24px",
                            backdropFilter: "brightness(50%)",
                            width: "100%",
                            height: "100%",
                            display: "flex"
                        }}>
                            <div style={{
                                marginRight: "auto",
                                marginTop: "auto",
                                width: "100%",
                                height: "100%",
                            }}>
                                <CafeTypography color="white" variant="h3">{translateByLocales(category.name, language)}</CafeTypography>
                            </div>

                        </div>

                    </ButtonBase>)
                })}
            </div>
        </Fade> : <>
            {widgets.map((w) => {
                return <Widget widget={w} key={w.id} />
            })}
        </>
    );
}
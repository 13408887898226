import { RoleDTO } from "../Api/ApiConnector";
import { AuthContext, AuthContextValue } from "./auth";
import { LanguageContext } from "./language";
import { defaultStyle, typographyStyle } from "./style";
import { Tab, TabContext, TabCreator } from "./tabs";
import { theme } from "./theme";
import { translateByLocales } from "./translateByLocales";

export function hasPermission(action: string, table: string, roles: RoleDTO[], root: boolean): boolean {
    if (root) return true;
    
    for (const r of roles) {
        if (r.table !== table) continue;
        for (const p of r.permissions) {
            if (p === action) return true;
        }
    }

    return false;
}

export {
    AuthContext, LanguageContext,
    Tab, TabContext,
    TabCreator, defaultStyle,
    theme, translateByLocales, typographyStyle
};
export type { AuthContextValue };


import { Button, Stack } from "@mui/material";
import Image from "mui-image";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadFileProps } from "./AdminRoom";

export const UploadFile = (props: UploadFileProps) => {
    const { onChange, url } = props;
    const [image, setImage] = useState<File | null>();
    const { t } = useTranslation();

    useEffect(() => {
        const load = async () => {
            if (!url) return;
            setImage(await (await fetch(url)).blob() as File);
        };
        load();
    }, []);

    return <Stack>
        {image ? <Image height="100%" width="100%"
            src={URL.createObjectURL(image)} /> : null}
        <Button
            variant="contained"
            component="label"
        >
            {t("data.uploadImage")}
            <input onChange={async (e) => {
                if (!e.currentTarget.files) return;
                const file = e.currentTarget.files[0];
                setImage(file);
                onChange(file);
            }}
                type="file"
                accept="Image/*"

                hidden />
        </Button>

    </Stack>;
};

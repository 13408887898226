import env from "react-dotenv";

export interface ProductDTO {
    id: string;
    category: string | (string | null)[] | null;
    thumbnail?: File;
    visible: boolean
    name: LocalizableDTO[];
    description: LocalizableDTO[];
    price: number;
}

export interface UserDTO {
    id: string;
    username: string;
    password: string;
    roles: string[];
}

export interface RoleDTO {
    name: string
    table: string
    permissions: string[]
    id: string
} 

export interface CategoryDTO {
    id: string;
    thumbnail?: File;
    visible: boolean
    name: LocalizableDTO[];
}

export interface CreateCategoryDTO {
    name: LocalizableDTO[];
    visible: boolean
}

export interface LocalizableDTO {
    language: string
    value: string
}

export interface CreateProductDTO {
    name: LocalizableDTO[];
    description: LocalizableDTO[];
    price: number;
    visible: boolean
    thumbnail: File
}

interface BearerToken {
    token: string
}

export interface CredentialInfoDTO {
    username: string
    password: string
}

type Err = (error: string) => void

export type Languages = "ru" | "ee" | "en";
export type Controller = "Product" | "Auth" | "Category" | "Gallery" | "User" | "Role";

export class ApiConnector {
    public static readonly url: string | undefined = env.API_URL
    private static readonly requestInit: RequestInit = {
        mode: "cors",
        headers: {
            "Access-Control-Allow-Origin": "*"
        }
    }

    private static getParams(controller: Controller, method?: string): string {

        return `${ApiConnector.url}api/${controller}/${method}`
    }

    public static async getCategories(): Promise<CategoryDTO[]> {
        try {
            const categories = (await fetch(this.getParams("Category",""), ApiConnector.requestInit))
                .json() as Promise<CategoryDTO[]>;
            return categories;
        } catch (error) {
            console.log(error)

            return [];
        }
    }

    public static async getAllCategories(token: string, onError: (err: string) => void): Promise<CategoryDTO[]> {
        const resp = await fetch(this.getParams("Category", "All"), {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            }   
        })
        if (!resp.ok) {
            if (onError) {
                onError(await resp.text())
            }
            return [];
        }
        return await resp.json() as CategoryDTO[];
    }

    public static async getProducts(token: string, onError: (err: string) => void): Promise<ProductDTO[]> {
        const resp = await fetch(this.getParams("Product", "All"), {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            }   
        })
        if (!resp.ok) {
            if (onError) {
                onError(await resp.text())
            }
            return [];
        }
        return await resp.json() as ProductDTO[];
    }

    public static async getProduct(id: string): Promise<ProductDTO | null> {
        try {
            const product = (await fetch(this.getParams("Product", id), ApiConnector.requestInit))
                .json() as Promise<ProductDTO>;
            return product;
        } catch (error) {
            console.log(error);

            return null;
        }
    }

    public static async getProductsByCategory(categoryId: string): Promise<ProductDTO[]> {
        const products = (await fetch(this.getParams("Product", "Category/" + categoryId), ApiConnector.requestInit))
            .json() as Promise<ProductDTO[]>;
        return products;
    }

    public static async deleteProduct(id: string, token: string, onError?: (error: string) => void): Promise<boolean> {
        const resp = await fetch(this.getParams("Product", id), {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            }   
        })
        if (!resp.ok) {
            if (onError) {
                onError(await resp.text())
            }
            return false;
        }
        return true;
    }

    public static async deleteCategory(id: string, token: string, onError?: (error: string) => void): Promise<boolean> {
        const resp = await fetch(this.getParams("Category", id), {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            }
        })
        if (!resp.ok) {
            if (onError) {
                onError(await resp.text())
            }
            return false;
        }
        return true;
    }

    public static async postCategory(category: CreateCategoryDTO, token: string, onError?: (error: string) => void): Promise<CategoryDTO | null> {
        const resp = await fetch(this.getParams("Category", ""), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(category)
        });
        if (resp.ok) {
            return await resp.json() as Promise<CategoryDTO>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

    public static async postProduct(product: CreateProductDTO, token: string, onError?: (error: string) => void): Promise<ProductDTO | null> {
        const resp = await fetch(this.getParams("Product", ""), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(product)
        });
        if (resp.ok) {
            return await resp.json() as Promise<ProductDTO>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

    public static async updateCategory(id: string, category: CategoryDTO, token: string, onError?: (error: string) => void): Promise<CategoryDTO | null> {
        const resp = await fetch(this.getParams("Category", id), {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(category)
        });

        if (resp.ok) {
            return await resp.json() as Promise<CategoryDTO>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

    public static async updateProduct(id: string, product: ProductDTO, token: string, onError?: (error: string) => void): Promise<ProductDTO | null> {
        const resp = await fetch(this.getParams("Product", id), {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(product)
        });

        if (resp.ok) {
            return await resp.json() as Promise<ProductDTO>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

    public static async login(login: string, password: string): Promise<string | null> {
        try {
            const coupled: CredentialInfoDTO = {
                username: login,
                password: password
            }
            const resp = await fetch(this.getParams("Auth", "login"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(coupled)
            });
            if (resp.ok) {
                const content = await resp.json() as BearerToken;
                localStorage.setItem("token", content.token);
                return content.token;
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    public static async checkLogin(token: string): Promise<boolean> {
        try {
            const resp = await fetch(this.getParams("Auth", ""), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token
                },
            });
            return resp.ok;
        } catch (error) {
            console.log(error);

            return false;
        }
    }

    public static async getAllImages(): Promise<string[]> {
        try {
            const resp = await fetch(this.getParams("Gallery", ""))
            const array = await resp.json() as string[]
            return array.map(x => {
                return this.url + x
            });
        } catch (error) {
            return [];
        }
    }

    public static async deleteImage(id: string, token: string, type: Controller, onError?: (error: string) => void) {
        const req = new XMLHttpRequest()
        req.open("DELETE", this.getParams(type, id))
        req.setRequestHeader('Authorization',token);
        req.send()
    }

    public static async postImage(form: FormData, token: string, type: Controller, onError?: (error: string) => void) {
        const resp = await fetch(this.getParams(type, ""), {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: token
            },
            body: form
        });

        if (!resp.ok && onError) {
            onError(await resp.text())
        }
    }
    public static async putImage(id: string, form: FormData, token: string, type: Controller, onError?: (error: string) => void) {
        const resp = await fetch(this.getParams(type, id), {
            method: "PUT",
            mode: "cors",
            headers: {
                Authorization: token
            },
            body: form
        });

        if (!resp.ok && onError) {
            onError(await resp.text())
        }
    }
    public static getImageURL(id: string, type: Controller): string {
        return `${this.url}static/${type}/${id}`
    }

    public static async isRoot(token: string): Promise<boolean> {
        const resp = await fetch(this.getParams("User", "Root"), {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            }   
        })
        
        return resp.ok
    }


    public static async getTs<T>(token: string, type: Controller, method: string, onError?: Err): Promise<T[]> {
        try {
            const resp = await fetch(this.getParams(type, method), {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: token
                }   
            })
            if (!resp.ok) {
                if (onError) {
                    onError(await resp.text())
                }
                return [];
            }
            return await resp.json() as T[];
        } catch (error) {
            return [];
        }
    }

    public static async updateT<T>(id: string, data: T, token: string, type: Controller, method: string, onError?: Err): Promise<T | null> {
        const resp = await fetch(this.getParams(type, id), {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(data)
        });

        if (resp.ok) {
            return await resp.json() as Promise<T>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

    public static async createT<T>(data: T, token: string, type: Controller, onError?: Err): Promise<T | null> {
        const resp = await fetch(this.getParams(type, ""), {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: JSON.stringify(data)
        });

        if (resp.ok) {
            return await resp.json() as Promise<T>
        } else {
            if (onError) {
                onError(await resp.text())
            }
            return null;
        }
    }

    public static async delete(id: string, token: string, type: Controller, onError?: Err): Promise<void> {
        const resp = await fetch(this.getParams(type, id), {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: token
            }
        })
        if (!resp.ok) {
            if (onError) {
                onError(await resp.text())
            }
        }
    }
}
import { Drawer } from "@material-ui/core";
import { Menu, Room } from "@material-ui/icons";
import { ButtonBase, DrawerProps, IconButton, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { AboutUs, Contacts, MenuBakery } from "../Pages";
import { AdminRoom } from "../Pages/Admin";
import { GalleryRoom } from "../Pages/Admin/GalleryRoom";
import { UserRoom } from "../Pages/Admin/UserRoom";
import { WidgetEditor } from "../Pages/Admin/WidgetEditor";
import { GalleryPage } from "../Pages/GalleryPage";
import { MainHome } from "../Pages/Home";
import { AuthContext, Tab, TabContext, TabCreator, hasPermission } from "../utilities";
import { useWindowSize } from "../utilities/useWindowSize";
import { CafeTypography } from "./CafeTypography";
import { LanguageSelect } from "./LanguageSelect";

export interface HeaderProps {
  createTabs?: boolean
  languages?: boolean
  children?: JSX.Element | JSX.Element[]
  backgroundColor?: string
}

export interface DrawerSideBarProps {
  tabs: TabCreator[] | undefined;
}

const DrawerSideBar = (props: DrawerSideBarProps & DrawerProps) => {
  const { tabs } = props;
  const nav = useNavigate();
  return (
    <Drawer {...props} style={{
      display: "flex"
    }}>
      {tabs?.map(t => t.finish(nav))}
    </Drawer>
  );
}

export const Header = (props: HeaderProps) => {
  const { t } = useTranslation()
  const nav = useNavigate();
  const { isLogin, roles, isRoot } = useContext(AuthContext);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { currentTab, setCurrentTab } = useContext(TabContext);
  const [screenWidth] = useWindowSize();
  const { createTabs, languages, children, backgroundColor } = props;

  useEffect(()=>{}, [roles]);

  const canViewProductsCategories = hasPermission("SELECT", "categories", roles, isRoot) || hasPermission("SELECT", "products", roles, isRoot)
  const canViewWidgets = hasPermission("SELECT", "widgets", roles, isRoot)
  const canViewGallery = hasPermission("SELECT", "gallery_assets", roles, isRoot)
  const canViewUsersRoles = hasPermission("SELECT", "users", roles, isRoot) || hasPermission("SELECT", "permissions", roles, isRoot)

  const MOBILE_WIDTH = 976;

  const tabCreator = TabCreator.createTab()
    .addTab(t("app.aboutUs.title"), "about", <AboutUs />)
    .addTab(t("app.contacts.title"), "contacts", <Contacts />)
    .addTab(t("app.menu"), "menu", <MenuBakery />)
    .addTab(t("app.gallery"), "gallery", <GalleryPage />)

  const adminTabCreator = TabCreator.createTab()
    .addTabConditional(canViewProductsCategories, t("system.adminRoom"), "adminRoom", <AdminRoom />)
    .addTabConditional(canViewGallery, `${t("system.adminRoom")}/${t("app.gallery")}`, "adminGallery", <GalleryRoom />)
    .addTabConditional(canViewWidgets, "Widgets", "adminWidgets", <WidgetEditor />)
    .addTabConditional(canViewUsersRoles, "Users", "usrs", <UserRoom />)

  useEffect(() => {
    setDrawerOpen(false);
  }, [currentTab, screenWidth])

  return (
    <Stack sx={{
      backgroundColor: backgroundColor,
    }} flexWrap="wrap" display="flex" direction={children ? "column" : "row"} alignItems="end">
      <div>
        {children}
      </div>

      {createTabs && screenWidth <= MOBILE_WIDTH ? <IconButton sx={{
        marginBottom: "auto"
      }} onClick={() => setDrawerOpen(true)}>
        <Menu htmlColor="white" />
      </IconButton> : null}

      {createTabs ?
        <>
          <IconButton onClick={() => {
            const url = "https://www.google.com/maps/place/Ahtme+mnt+16,+Ahtme,+31025+Ida-Viru+maakond/@59.329406,27.40954,1585m/data=!3m1!1e3!4m6!3m5!1s0x469465a13e162881:0xddecd94eff9ba4a3!8m2!3d59.3301505!4d27.410315!16s%2Fg%2F11cs896nc0?hl=en&entry=ttu";
            window.open(url);
          }}>
            <Room htmlColor="white" />
          </IconButton>
          <ButtonBase onClick={() => {
            if (!setCurrentTab) return;
            nav("/");
            setCurrentTab(new Tab(t("app.home.title"), "home", <MainHome />))
          }}>
            <CafeTypography fontSize="18pt" variant="h4">Kohvik Söör Leiton</CafeTypography>
          </ButtonBase>
        </> : null}

      <Stack direction="row" sx={{ marginLeft: "auto" }}>
        {screenWidth > MOBILE_WIDTH && createTabs ? <>
          {tabCreator.finish(nav, { color: "white" })}
        </> : null}

        {screenWidth > MOBILE_WIDTH && createTabs && isLogin ? adminTabCreator.finish(nav, { color: "white" }) : null}
        {languages ? <LanguageSelect style={{
          marginLeft: "auto"
        }} /> : null}
      </Stack>
      <DrawerSideBar open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        tabs={createTabs ? [tabCreator] : []} />
    </Stack>
  )
}